<div class="bg-image"
    style="background-image: url('../../assets/BG\ for\ results\ trial.png'); height: 100vh;background-size: cover;">
    
    <video-iframe [_situation]="_currentSiutation" (videoEnded)="onVideoEnd($event)"></video-iframe>
    <div *ngIf="showOptions" class="progress" style="height: 5px;">
        <div #progress class="progress-bar" role="progressbar" [ngStyle]="{'width': seconds*5 + '%', 'height':'5px'}" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    <div *ngIf="showOptions == true" class="d-flex justify-content-around mt-5 text-decoration-underline">
        <h4 *ngFor="let choice of _currentSiutation.choices" class="text-white user-select-none" style="cursor: pointer;"
            (click)="choiceClicked(choice)">{{choice.text}}</h4>
    </div>
</div>