import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rules-page',
  templateUrl: './rules-page.component.html',
  styleUrls: ['./rules-page.component.scss']
})
export class RulesPageComponent implements OnInit {
  @Output() gameStarted = new EventEmitter<string>();
  username: string = ""
  constructor() { }

  ngOnInit(): void {
  }
  onStartClicked() {
    this.gameStarted.emit(this.username);
  }

}
