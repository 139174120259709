import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VideoIframeComponent } from './video-iframe/video-iframe.component';
import { SafePipe } from './safe.pipe';
import { OptionDisplayComponent } from './option-display/option-display.component';
import { GameComponent } from './game/game.component';
import { TitlePageComponent } from './pages/title-page/title-page.component';
import { CharsIntroPageComponent } from './pages/chars-intro-page/chars-intro-page.component';
import { InstructionsPageComponent } from './pages/instructions-page/instructions-page.component';
import { RulesPageComponent } from './pages/rules-page/rules-page.component';
import { FormsModule } from '@angular/forms';
import { GameResult2Component } from './game-result2/game-result2.component';
import { GameResultCharComponent } from './game-result-char/game-result-char.component';

@NgModule({
  declarations: [
    AppComponent,
    VideoIframeComponent,
    SafePipe,
    OptionDisplayComponent,
    GameComponent,
    TitlePageComponent,
    CharsIntroPageComponent,
    InstructionsPageComponent,
    RulesPageComponent,
    GameResult2Component,
    GameResultCharComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
