<audio autoplay hidden loop id="bg-audio">
    
    <source src="../../assets/New Audio Track Mindrealm.mp3" type="audio/mp3">
</audio>

<div *ngIf="currentPage == 1">
    <app-title-page (titlePageContinueClicked)="onTitlePageContinueClicked()"></app-title-page>
</div>

<div *ngIf="currentPage == 2">
    <app-chars-intro-page (charsIntroContinueClicked)="onCharsIntroPageContinueClicked()"></app-chars-intro-page>
</div>

<div *ngIf="currentPage == 3">
    <app-instructions-page (instructionsContinueClicked)="onInstructionsPageContinueClicked()"></app-instructions-page>
</div>

<div *ngIf="currentPage == 4">
    <app-rules-page (gameStarted)="onGameStarted($event)"></app-rules-page>
</div>

<div *ngIf="currentPage == 5">
    <option-display (gameEnded)="onGameEnded($event)"></option-display>
</div>

<div *ngIf="currentPage == 6">
    <app-game-result2 [choices]="choices" [name]="name"></app-game-result2>
</div>