<div class="fadein-5 row mx-0 bg-image d-flex justify-content-center align-items-center"
    style="background-repeat: round;background-image: url('../../assets/Background\ picture\ for\ vids.png');background-size: cover;">
    <div class="d-flex flex-column align-items-center justify-content-center mt-3 text-white" *ngIf="personalitiesResult?.length > 1">
        <h3>MORE THAN ONE?!</h3>
        <a href="#result2" class="h5">SCROLL TO SEE RESULTS</a>
    </div>
    <div class="d-flex flex-column">
        <div *ngFor="let result of personalitiesResult; let i = index" [id]="'result'+(i+1)">
            <app-game-result-char [isFirst]="i==0" [userName]="name" [charName]="map[result].name" [videoSrc]="map[result].videoSrc" [paragraphs]="map[result].paragraphs"></app-game-result-char>
        </div>
    </div>
</div>