
export enum PersonalityType{
    Narcissism,
    Machiavellianism,
    Psychopathy
}
export interface Choice {
    text: string;
    points: number;
    type: PersonalityType
}

export interface Situation {
    youtubeId: string;
    choices: Choice[]
}