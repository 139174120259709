<div class="d-flex justify-content-center gap-4 ">
<div class="d-flex justify-content-center align-items-center"
        style="position: relative;left: 150px">
        <div class="outer">
            <div class="inner rotate">
                <h1 style="font-size: 80px;font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;"
                            class="h1 text-bold text-white">{{charName}}</h1>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center">
        <div class="video-frame" style="
                        position: absolute;
                        width: 250px;
                        height: 420px;"></div>
        <video width="90%" style="position: relative;" autoplay loop [src]="videoSrc"></video>

    </div>
    <div class="d-flex flex-column justify-content-center text-white h6 col-4" style="font-size: 20px;position: relative;left: -9rem;">
        <div *ngIf="isFirst">
            <p class="h1"> Well {{userName}},</p>
        </div>
        <p *ngFor="let paragraph of paragraphs">
            {{paragraph}}
        </p>
    </div>
</div>