import { Component, Input, OnInit } from '@angular/core';
import { Choice, PersonalityType } from 'src/models/Option';

@Component({
  selector: 'app-game-result2',
  templateUrl: './game-result2.component.html',
  styleUrls: ['./game-result2.component.scss']
})
export class GameResult2Component implements OnInit {
  @Input() choices: Choice[];
  @Input() name: string;
  personalitiesResult: PersonalityType[];
  PersonalityTypes = PersonalityType;

  map = {
    0: {
      name: "Narcissism",
      videoSrc: "../../assets/Narc Cropped.webm",
      paragraphs: [
        `If you identify as someone with high levels of narcissism, it's important to understand that this trait can have negative impacts on your relationships and overall well-being. Narcissism is characterized by a grandiose sense of self-importance, a lack of empathy for others, and a constant need for admiration and attention.`,
        `It's important to recognize that everyone has flaws and imperfections, and that seeking perfection or constant validation can be unhealthy and unsustainable. Consider seeking support from a therapist or counselor to work on building healthier relationships and improving your overall emotional well-being.`,
        `Remember, while it's important to value yourself and your accomplishments, it's equally important to treat others with kindness, empathy, and respect.`
      ]
    },
    1: {
      name: "Machiavellianism",
      videoSrc: "../../assets/Vand Cropped.webm",
      paragraphs: [
        `If you identify as someone with high levels of Machiavellianism, it's important to recognize that this trait can have negative impacts on your relationships and overall well-being. Machiavellianism is characterized by a manipulative and exploitative approach to others, a willingness to deceive and manipulate to achieve one's goals, and a lack of concern for the well-being of others.`,
        `While some people may view these traits as advantageous in certain situations, it's important to consider the long-term consequences of these behaviors. Manipulating and exploiting others can damage relationships and lead to a lack of trust and respect from others.`,
        `Consider seeking support from a therapist or counselor to work on building healthier relationships and improving your overall emotional well-being. Remember, treating others with kindness, empathy, and respect is important for building strong and fulfilling relationships.`
      ]
    },
    2: {
      name: "Psychopathy",
      videoSrc: "../../assets/psyco_cropped.webm",
      paragraphs: [
        `If you identify as someone with high levels of psychopathy, it's important to recognize that this trait can have negative impacts on your relationships and overall well-being. Psychopathy is characterized by a lack of empathy and remorse, a tendency towards impulsive and reckless behavior, and a disregard for the rights and well-being of others.`,
        `While some people may view these traits as advantageous in certain situations, it's important to consider the long-term consequences of these behaviors. Engaging in impulsive or reckless behavior can put yourself and others in danger, and a lack of empathy and concern for others can damage relationships and lead to a lack of trust and respect from others.`,
        `Consider seeking support from a therapist or counselor to work on developing empathy and improving your overall emotional well-being. Remember, treating others with kindness, empathy, and respect is important for building strong and fulfilling relationships.`
      ]
    }
  }

  constructor() { }

  ngOnInit(): void {
    // this.choices = [ { "text": "Machissism 2", "points": 2, "type": 1 }, { "text": "Machissism 2", "points": 20, "type": 1 }, { "text": "Psyco 0", "points": 2, "type": 2 }, { "text": "Narcissism 0", "points": 2, "type": 0 }, { "text": "Psyco 1", "points": 20, "type": 2 }, { "text": "Narcissism 1", "points": 20, "type": 0 } ]
    // this.name = "Sara"
    // console.log(this.choices);
    
    this.calculatePersonalities();
  }

  calculatePersonalities(){
    let res = [];
    let map2 = this.choices.reduce((prev, next) =>{
      if (next.type in prev) {
        prev[next.type] += next.points;
      } else {
         prev[next.type] = next.points;
      }
      return prev;
    }, {});
    let scores = <number[]>Object.values(map2);
    let maxScore = Math.max(...scores);
    console.log(scores, this.choices);
    this.personalitiesResult = <PersonalityType[]>(<unknown[]> Object.keys(map2).filter(c => map2[c] == maxScore));
  }



}
