<div class="bg-image d-flex justify-content-center align-items-center flex-column"
    style="background-image: url('../../../assets/Background\ picture\ for\ vids.png'); height: 100vh;background-size: cover;">

    <div class="fadein-5 d-flex justify-content-center">
        <div class="mx-4 text-center p-3 instruction" >
            <h1 class="text-white my-4">6 Situations</h1>
            <p class="text-white my-4 h5">
                In this activity, you will be presented with six situations in the form of short videos. Each video will depict a scenario where someone encounters a problem or a challenge, and you will be asked to choose from a set of options how you would react in that situation.
            </p>
            <p class="text-white my-4 h5">
                Your responses will be based on your personal values, beliefs, and experiences. There are no right or wrong answers, and the purpose of this activity is to help you reflect on how you would handle different situations and to learn more about yourself.
            </p>
        </div>
        <div class="mx-4 text-center p-3 instruction">
            <h1 class="text-white my-4">20 Seconds</h1>
            <p class="text-white my-4 h5">
                You will be presented with six situations in the form of short videos. For each video, you will have 20 seconds to choose from a set of options how you would react in that situation.
            </p>
            <p class="text-white my-4 h5">
                Take your time to watch each video, but please make sure to choose your response within the given 20 seconds. Once you have completed all six situations, you will have a chance to review your personality based on the choices you have made.
            </p>
        </div>
    </div>

    <div>
        <button type="button" class="btn btn-primary fadein-5 m-3 my-btn" (click)="onContinueClicked()"><p class="h4">Continue >></p></button>

        <!-- <h2 class="text-white m-3 fadein-5 user-select-none" style="cursor: pointer;" (click)="onContinueClicked()">Continue >></h2> -->
    </div>
</div>