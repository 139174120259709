import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-title-page',
  templateUrl: './title-page.component.html',
  styleUrls: ['./title-page.component.scss']
})
export class TitlePageComponent implements OnInit {

  @Output() titlePageContinueClicked = new EventEmitter<string>();

  loading = 0;
  interval;

  constructor() { }

  ngOnInit(): void {
    this.initLoading();
  }

  initLoading(){
    this.interval = setInterval(()=>{
      this.loading++;
      if(this.loading == 150){
        clearInterval(this.interval);
      }
    },50)
  }

  onContinueClicked(){
    this.titlePageContinueClicked.emit("")
  }
}
