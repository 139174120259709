import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chars-intro-page',
  templateUrl: './chars-intro-page.component.html',
  styleUrls: ['./chars-intro-page.component.scss']
})
export class CharsIntroPageComponent implements OnInit {

  @Output() charsIntroContinueClicked = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onContinueClicked(){
    // console.log("???")
    this.charsIntroContinueClicked.emit("");
  }

}
