import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-instructions-page',
  templateUrl: './instructions-page.component.html',
  styleUrls: ['./instructions-page.component.scss']
})
export class InstructionsPageComponent implements OnInit {
  @Output() instructionsContinueClicked = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onContinueClicked() {
    this.instructionsContinueClicked.emit("")
  }

}
