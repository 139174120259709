<div class="bg-image d-flex justify-content-center align-items-center"
    style="background-image: url('../../../assets/Background\ picture\ for\ vids.png'); height: 100vh;background-size: cover;">

    <div class="fadein-5 d-flex justify-content-center ">
        <div class="d-flex flex-column mx-4 text-center text-white h4 p-4 instruction align-items-center" >
            <h2>Rules</h2>
            <p class="h5 my-2">In order to ensure accuracy for the experience, please answer honestly, your data and answers are not being collected.</p>
            <p class="h5 my-2">You have 20 seconds after each video to choose how to react, be sure to answer them all within the time frame to ensure accuracy.</p>
            <div class="row col-6 my-2">
                <input type="text" class="form-control" maxlength="20" placeholder="Your name..." [(ngModel)]="username">
            </div>
            <div *ngIf="username" >
                <button type="button" class="btn btn-primary fadein-5 m-3 my-btn" (click)="onStartClicked()"><p class="h4">Continue >></p></button>

                <!-- <p class="text-white m-3 fadein-5 user-select-none" style="cursor: pointer;" (click)="onStartClicked()">Start now!</p> -->
            </div>
            
        </div>
    </div>
</div>