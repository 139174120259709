import { Component, Input, OnInit, Output, EventEmitter, OnChanges, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Situation } from 'src/models/Option';

@Component({
  selector: 'video-iframe',
  templateUrl: './video-iframe.component.html',
  styleUrls: ['./video-iframe.component.scss']
})
export class VideoIframeComponent implements OnInit, OnChanges {

  @Input() _situation: Situation;
  @Output() videoEnded = new EventEmitter<string>();
  @ViewChild("player") HTMLplayer: ElementRef;
  end = false;
  constructor(private ngZone: NgZone) { }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(e) {
    this.player?.loadVideoById(this._situation.youtubeId);
  }

  public YT: any;
  public video: any;
  public player: any;
  public reframed: Boolean = false;

  init() {
    this.video = this._situation.youtubeId;
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    /* 3. startVideo() will create an <iframe> (and YouTube player) after the API code downloads. */
    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }

  startVideo() {
    this.reframed = false;
    this.player = new window['YT'].Player('player', {
      videoId: this.video,
      playerVars: {
        autoplay: 1,
        modestbranding: 1,
        controls: 0,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playsinline: 1
      },
      events: {
        'onStateChange': (event) => this.ngZone.run(() => this.onPlayerStateChange(event)),
        'onError': (event) => this.ngZone.run(() => this.onPlayerError(event)),
        'onReady': (event) => this.ngZone.run(() => this.onPlayerReady(event))
      }
    });
  }

  /* 4. It will be called when the Video Player is ready */
  onPlayerReady(event) {
    event.target.playVideo();
  }

  /* 5. API will call this function when Player State changes like PLAYING, PAUSED, ENDED */
  onPlayerStateChange(event) {
    // console.log(event, window['YT'].PlayerState)
    switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
        if (this.cleanTime() == 0) {
          // console.log('started ' + this.cleanTime());
        } else {
          // console.log('playing ' + this.cleanTime())
        };
        break;
      case window['YT'].PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
          // console.log('paused' + ' @ ' + this.cleanTime());
        };
        break;
      case window['YT'].PlayerState.ENDED:
        // console.log((this.player.getDuration()));
        
        this.player.seekTo(this.player.getDuration() - 1, false);
        this.player.pauseVideo();

        this.videoEnded.emit('');
        break;
    }
  }
  cleanTime() {
    return Math.round(this.player.getCurrentTime())
  }
  onPlayerError(event) {
    // console.log("error!", event);
    
    switch (event.data) {
      case 2:
        // console.log('' + this.video)
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  }
}


