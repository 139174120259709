import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-result-char',
  templateUrl: './game-result-char.component.html',
  styleUrls: ['./game-result-char.component.scss']
})
export class GameResultCharComponent implements OnInit {


  @Input() videoSrc: string;
  @Input() charName: string;
  @Input() paragraphs: string[];
  @Input() userName: string;
  @Input() isFirst: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
