<!-- <div class="bg-image" 
     style="background-image: url();
            height: 100vh; background-size: cover;">
            
            <div class="d-flex">
                <button>HIii</button>
            </div>
</div> -->

<div style="display: none;">
  <video src="../../../assets/Narc Cropped.webm"></video>
  <video src="../../../assets/psyco_cropped.webm"></video>
  <video src="../../../assets/Vand Cropped.webm"></video>
</div>

<div class="bg-image d-flex justify-content-center align-items-end fadein-2" style="
    background-image: url('../../../assets/Page 1 title page.png');
    height: 100vh;background-size: cover;background-position: top center;
  ">
  <!-- <div class="my-btn">
  <h2 class="text-white m-3 fadein-5 user-select-none " style="cursor: pointer;" (click)="onContinueClicked()">Continue >></h2>
</div> -->

  <button *ngIf="loading >=120" type="button" class="btn btn-primary cta my-btn fadein-5" (click)="onContinueClicked()">
    <p class="h4">Start now</p>
  </button>

    <div class="progress col-6 cta" *ngIf="loading < 120">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': loading + '%'}" >
        {{loading > 100 ? 100: loading}}%
      </div>
    </div>
</div>