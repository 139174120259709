import { Component, Input, OnInit, OnChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import {Situation, Choice, PersonalityType } from 'src/models/Option';


let situations = <Situation[]>[
  //#region narc Situations
  {
    youtubeId: "LPfd4u6NIfk",
    choices: [
      {
        text: "Stay where you are",
        points: 0,
        type: PersonalityType.Narcissism
      },
      {
        text: "Go to the front, but on your knees",
        points: 1,
        type: PersonalityType.Narcissism
      },
      {
        text: "Go to the front",
        points: 2,
        type: PersonalityType.Narcissism
      }
    ]
  },
  {
    youtubeId: "eUPircOn7Og",
    choices: [
      {
        text: "Leave the party",
        points: 0,
        type: PersonalityType.Narcissism
      },
      {
        text: "Explain that they're being rude",
        points: 1,
        type: PersonalityType.Narcissism
      },
      {
        text: "Make up an interesting story",
        points: 2,
        type: PersonalityType.Narcissism
      }
    ]
  },
  //#endregion

  //#region Mach Situations
  {
    youtubeId: "s8Kd3pxPmpw",
    choices: [
      {
        text: "Share your current plan",
        points: 0,
        type: PersonalityType.Machiavellianism
      },
      {
        text: "Promise Change",
        points: 1,
        type: PersonalityType.Machiavellianism
      },
      {
        text: "Promise Job Opportunities, Wealth and a Better life",
        points: 2,
        type: PersonalityType.Machiavellianism
      }
    ]
  },
  {
    youtubeId: "FTv1Z4lWrrY",
    choices: [
      {
        text: "Quit job",
        points: 0,
        type: PersonalityType.Machiavellianism
      },
      {
        text: "Expose other employee’s dishonesty",
        points: 1,
        type: PersonalityType.Machiavellianism
      },
      {
        text: "Spread a Rumor about other employee",
        points: 2,
        type: PersonalityType.Machiavellianism
      }
    ]
  },
  //#endregion

  //#region Psyco Situations
  {
    youtubeId: "_tbYOfr2oMo",
    choices: [
      {
        text: "Call Ambulance and start CPR till they arrive",
        points: 0,
        type: PersonalityType.Psychopathy
      },
      {
        text: "Call an ambulance but leave before they arrive",
        points: 1,
        type: PersonalityType.Psychopathy
      },
      {
        text: "Leave quickly or you'll risk going to prison",
        points: 2,
        type: PersonalityType.Psychopathy
      }
    ]
  },
  {
    youtubeId: "F019CH87RfQ",
    choices: [
      {
        text: "You can't do it, delegate to another person",
        points: 0,
        type: PersonalityType.Psychopathy
      },
      {
        text: "Send him an email informing him",
        points: 1,
        type: PersonalityType.Psychopathy
      },
      {
        text: "Inform him he's fired",
        points: 2,
        type: PersonalityType.Psychopathy
      }
    ]
  }
  //#endregion

]

@Component({
  selector: 'option-display',
  templateUrl: './option-display.component.html',
  styleUrls: ['./option-display.component.scss']
})
export class OptionDisplayComponent implements OnInit, OnChanges {
  totalSeconds = 20.0;
  @Output() gameEnded = new EventEmitter<Choice[]>();
  seconds = 20.0;
  showOptions: boolean;
  _currentSiutation: Situation = null;
  currentSiutationIndex = 0;
  choices: Choice[] = [];
  constructor() { }

  ngOnInit(): void {
    this.showOptions = false;
    situations = this.shuffleArray(situations);
    situations.map(s => s.choices = this.shuffleArray(s.choices));
    this._currentSiutation = situations[0];
  }

  ngOnChanges(e) {
  }


  choiceClicked(choice: Choice) {
    this.choices.push(choice);
    this.showOptions = false;
    this.seconds = this.totalSeconds
    this.currentSiutationIndex++;
    if (this.currentSiutationIndex >= situations.length) {
      this.gameEnded.emit(this.choices);
    } else {
      this._currentSiutation = situations[this.currentSiutationIndex];
    }
  }

  onVideoEnd(e) {
    this.showOptions = true;
    this.countdown();
  }

  countdown() {
    // console.log(this.seconds);

    setTimeout(() => {
      if (this.seconds >= 0.1 && this.showOptions == true) {
        this.seconds -= 0.1;
        this.countdown();
      }
    }, 100);

  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
}
