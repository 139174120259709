import { Component, OnInit } from '@angular/core';
import { Choice } from '../../models/Option'

@Component({
  selector: 'game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {
  name: string = "";
  currentPage: number = 1;
  choices: Choice[];
  constructor() { }

  ngOnInit(): void {
  }

  onTitlePageContinueClicked() {
    this.startAudio();
    this.currentPage = 2;
  }

  onCharsIntroPageContinueClicked() {
    this.currentPage = 3;
  }

  onInstructionsPageContinueClicked() {
    this.currentPage = 4;
  }

  audio_fadeout() {
    let timer, myAudio = <HTMLAudioElement>document.getElementById("bg-audio");
    if(myAudio == null) return;
    if (myAudio.volume > 0) {
      // console.log(myAudio.volume);

      myAudio.volume -= Math.min(myAudio.volume, 0.2)
      timer = setTimeout(() => { this.audio_fadeout() }, 200);
    }
  }

  onGameStarted(username) {
    this.audio_fadeout();
    this.name = username;
    this.currentPage = 5;
  }

  startAudio(){
    let myAudio = <HTMLAudioElement>document.getElementById("bg-audio");
    myAudio.play();
    myAudio.volume = 1;
  }

  onGameEnded(choices: Choice[]){
    this.startAudio();
    this.choices = choices;
    this.currentPage = 6;
  }

}
